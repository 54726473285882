const API_URL = process.env.NEXT_PUBLIC_API_URL;

const jsonHeaders = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

let token = null;
export const saveToken = (_token) => token = _token;
export const formatToken = (override) => ({ Authorization: override || `Bearer ${token}` });
export const parseBody = (body) => (body ? JSON.stringify(body) : undefined);
export const hit = async (
  { url, method, authentication, overrideBaseUrl, raw },
  body,
  file,
  headerOverride,
) => {
  const rawResponse = await fetch((overrideBaseUrl || API_URL) + url, {
    method,
    body: parseBody(body),
    headers: {
      ...(authentication && token && formatToken()),
      ...(body && jsonHeaders),
      ...(file && { "Content-Type": "multipart/form-data" }),
      ...(headerOverride && headerOverride),
    },
  });
  if (raw) {
    return rawResponse.json();
  }
  let parsedResponse;
  try {
    parsedResponse = await rawResponse.json();
  } catch (err) {
    //
  }
  if (rawResponse.status < 200 || rawResponse.status >= 300) {
    return { error: `ERROR_CODE_${parsedResponse?.error?.code}` };
  }
  if (rawResponse.status === 204) return {};
  return parsedResponse;
};
