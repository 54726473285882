import { hit } from "./hit";
import { SESSION } from "./APIEndpoints";

const create = async (authentication) => hit(SESSION.CREATE, authentication );
const _delete = async () => hit(SESSION.DELETE);

const validate = async (session) => {
  const res = await hit(SESSION.VALIDATE(session));
  return res.data?.isValid || false;
};

export const sessions = {
  create,
  _delete,
  validate,
};
