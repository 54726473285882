import { hit } from "./hit";
import { SHOWS } from "./APIEndpoints";

const accessToken = process.env.NEXT_PUBLIC_SHOWS_ACCESS_TOKEN;
const terminate = (id) => hit(SHOWS.TERMINATE(id), null, null, { "x-access-token": accessToken });
const reveal = (id) => hit(SHOWS.REVEAL(id), null, null, { "x-access-token": accessToken });
const reset = (id) => hit(SHOWS.RESET(id), null, null, { "x-access-token": accessToken });
const revealQuestion = (id) => hit(SHOWS.REVEAL_QUESTION(id), null, null, { "x-access-token": accessToken });
const streamQuestion = (id) => hit(SHOWS.EMIT_QUESTION(id), null, null, { "x-access-token": accessToken });
const createShow = (body) => hit(SHOWS.CREATE, body, null, { "x-access-token": accessToken });

export const shows = {
  terminate,
  reveal,
  reset,
  createShow,
  revealQuestion,
  streamQuestion,
};
