import { hit } from "./hit";
import { USER } from "./APIEndpoints";

const create = async (authentication) => hit(USER.CREATE, authentication);
const reset = async (email) => hit(USER.RESET, { email });
const changePassword = async (body) => hit(USER.CHANGE_PASSWORD, body);
const update = async (fields) => hit(USER.UPDATE, fields);

export const users = {
  create,
  changePassword,
  reset,
  update,
};
