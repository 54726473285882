/* eslint-disable no-console */
import { hit } from "./hit";
import { PROFILE } from "./APIEndpoints";

const getMe = async () => hit(PROFILE.GET.ME);
const getOther = async (id) => hit(PROFILE.GET.OTHER(id));

const getMeActivity = async () => hit(PROFILE.ACTIVITY.GET);
const follow = async (id) => hit(PROFILE.RELATIONSHIPS.FOLLOW, { targetProfileId: id });
const unFollow = async (id) => hit(PROFILE.RELATIONSHIPS.UNFOLLOW, { targetProfileId: id });

const update = async (form) => hit(PROFILE.UPDATE.ME, form);
const updateAvatar = async (uri) => hit(PROFILE.UPDATE.AVATAR, uri, true, true);

const saveOnboard = async (array) => hit(PROFILE.ONBOARDING.POST(array));
const onboardDone = async () => hit(PROFILE.ONBOARDING.DONE);
const setRef = async (referCode) => hit(PROFILE.ONBOARDING.SET_REF, { referCode });
const getRef = async () => hit(PROFILE.ONBOARDING.GET_REF);
const getMini = async (id) => hit(PROFILE.GET.MINI(id));
const activate = (code) => hit(PROFILE.ACTIVATE(code));
const setNotification = async (notId) => hit(PROFILE.NOTIFICATIONS.UPDATE(notId));
const checkNotifications = async () => {
  const res = await hit(PROFILE.NOTIFICATIONS.GET);
  const unseen = res.totalUnseenCount;
  return unseen;
};

export const profiles = {
  getMe,
  getOther,
  getMeActivity,
  setNotification,
  activate,
  getMini,
  checkNotifications,
  follow,
  unFollow,
  saveOnboard,
  onboardDone,
  update,
  updateAvatar,
  setRef,
  getRef,
};
