const SHOWS_API_URL = process.env.NEXT_PUBLIC_SHOWS_API_URL;

module.exports = Object.freeze({
  IG_STORIES: { method: "POST", url: "/image", overrideBaseUrl: "https://tools.rather.sh" },
  SIGN_OUT: { method: "delete", url: "/sessions" },
  SESSION: {
    VALIDATE: (token) => ({ method: "get", url: `/sessions/${token}` }),
    CREATE: { method: "post", url: "/sessions" },
    DELETE: { method: "delete", url: "/sessions", authentication: true },
  },
  USER: {
    CHANGE_PASSWORD: { method: "put", url: "/users/password" },
    CREATE: { method: "post", url: "/users" },
    RESET: { method: "post", url: "/users/password-reset" },
    UPDATE: { method: "put", url: "/users/me", authentication: true },
  },
  PROFILE: {
    ACTIVATE: (code) => ({ method: "get", url: `/users/me/activation/${code}`, authentication: true }),
    GET: {
      MINI: (id) => ({ method: "get", url: `/profiles/mini-profile/${id}` }),
      ME: { method: "get", url: "/profiles/me", authentication: true },
      OTHER: (id) => ({ method: "get", url: `/profiles/profile/${id}`, authentication: true }),
    },
    UPDATE: {
      ME: { method: "put", url: "/profiles/me", authentication: true },
      AVATAR: { method: "put", url: "/profiles/me/avatar", authentication: true },
    },
    RELATIONSHIPS: {
      FOLLOW: { method: "post", url: "/profiles/me/relationships", authentication: true },
      UNFOLLOW: { method: "delete", url: "/profiles/me/relationships", authentication: true },
      GET: { method: "get", url: "/profiles/relationships", authentication: true },
    },
    ACTIVITY: {
      GET: { method: "get", url: "/profiles/activities", authentication: true },
    },
    CHALLENGES: {
      GET: { method: "get", url: "/profiles/challenges", authentication: true },
    },
    NOTIFICATIONS: {
      GET: { method: "get", url: "/profiles/simple-notifications", authentication: true },
      UPDATE: (notificationId) => ({ method: "put", url: `/profiles/simple-notifications?lastSeenId=${notificationId}`, authentication: true }),
    },
    ONBOARDING: {
      GET: (genres) => ({ method: "get", url: `/profiles/onboarding/items?genres=${genres.join(",")}`, authentication: true }),
      POST: (items) => ({ method: "post", url: `/profiles/onboarding/items?items=${items.join(",")}`, authentication: true }),
      DONE: { method: "post", url: "/profiles/onboarding/affinity", authentication: true },
      SET_REF: { method: "post", url: "/profiles/referred", authentication: true },
      GET_REF: { method: "get", url: "/profiles/referred", authentication: true },
    },
    TOP: {
      GET: {
        MOVIES: { method: "get", url: "/profiles/top/movies", authentication: true },
        SERIES: { method: "get", url: "/profiles/top/series", authentication: true },
        ACTORS: { method: "get", url: "/profiles/top/celebrities", authentication: true },
        GENRES: { method: "get", url: "/profiles/top/genres", authentication: true },
      },
      ADD: {
        MOVIES: (itemId) => ({ method: "post", url: `/profiles/top/add/movies/${itemId}`, authentication: true }),
        SERIES: (itemId) => ({ method: "post", url: `/profiles/top/add/series/${itemId}`, authentication: true }),
        ACTORS: (itemId) => ({ method: "post", url: `/profiles/top/add/celebrities/${itemId}`, authentication: true }),
      },
      UPDATE: {
        MOVIES: { method: "put", url: "/profiles/top/update/movies", authentication: true },
        SERIES: { method: "put", url: "/profiles/top/update/series", authentication: true },
        ACTORS: { method: "put", url: "/profiles/top/update/celebrities", authentication: true },
      },
    },
  },
  SEARCH: {
    PROFILES: { method: "get", url: "/profiles" },
    CELEBRITIES: { method: "get", url: "/items/celebrities" },
    MOVIES: { method: "get", url: "/items/movies" },
    SERIES: { method: "get", url: "/items/series" },
  },
  SHOWS: {
    GET_WINNERS_BY_ID: (showId) => ({ method: "GET", overrideBaseUrl: SHOWS_API_URL, url: `/shows/${showId}/winners` }),
    CREATE: { method: "POST", overrideBaseUrl: SHOWS_API_URL, url: "/shows" },
    RESET: (showId) => ({ method: "POST", overrideBaseUrl: SHOWS_API_URL, url: `/shows/${showId}/reset` }),
    TERMINATE: (showId) => ({ method: "POST", overrideBaseUrl: SHOWS_API_URL, url: `/shows/${showId}/termination`, raw: true }),
    REVEAL: (showId) => ({ method: "POST", overrideBaseUrl: SHOWS_API_URL, url: `/shows/${showId}/reveal`, raw: true }),
    REVEAL_QUESTION: (showId) => ({ method: "POST", overrideBaseUrl: SHOWS_API_URL, url: `/shows/${showId}/questions/reveals`, raw: true }),
    EMIT_QUESTION: (showId) => ({ method: "POST", overrideBaseUrl: SHOWS_API_URL, url: `/shows/${showId}/questions/streams`, raw: true }),
    NEXT: { method: "get", url: "/shows/next", overrideBaseUrl: SHOWS_API_URL },
    GET_BY_ID: (id) => ({ method: "get", url: `/shows/${id}`, overrideBaseUrl: SHOWS_API_URL }),
    SHOW_POST_ANSWERS: (showId, participantId) => ({ method: "POST", overrideBaseUrl: SHOWS_API_URL, url: `/shows/${showId}/participants/${participantId}/answers` }),
  },
  TRIVIAS: {
    GET_QUESTIONS: (id) => ({ method: "get", url: `/trivias/${id}/questions` }),
    POST_ANSWERS: (triviaId) => ({ method: "put", url: `/trivias/${triviaId}/answers`, authentication: true }),
    SHOW_POST_ANSWERS: (showId, participantId) => ({ method: "POST", overrideBaseUrl: SHOWS_API_URL, url: `/shows/${showId}/participants/${participantId}/answers`, authentication: true }),
    GET_RANKING: (id) => ({ method: "get", url: `/trivias/${id}/ranking` }),
    OVERVIEW: { method: "get", url: "/trivias/overview", authentication: true },
    SHOWS_ONLINE: { method: "get", url: "/shows", overrideBaseUrl: SHOWS_API_URL },
    SHOW_ONLINE: (showId) => ({ method: "get", url: `/shows/${showId}`, overrideBaseUrl: SHOWS_API_URL }),
    JOIN_SHOW: (showId) => ({ method: "post", url: `/shows/${showId}/participants`, overrideBaseUrl: SHOWS_API_URL }),
  },
  LISTS: {
    GET_LISTS: { method: "get", url: "/lists", authentication: true },
    GET_LIST: (listId) => ({ method: "get", url: `/lists/${listId}`, authentication: true }),
    CREATE: { method: "post", url: "/lists", authentication: true },
    DELETE: (listId) => ({ method: "delete", url: `/lists/${listId}`, authentication: true }),
    ADD: (listId) => ({ method: "put", url: `/lists/${listId}`, authentication: true }),
    DELETE_ITEM: (listId, itemId) => ({ method: "delete", url: `/lists/${listId}/${itemId}`, authentication: true }),
  },
  ITEMS: {
    GET: (itemId) => ({ method: "get", url: `/items/${itemId}` }),
    GET_VALUATIONS: (itemId) => ({ method: "get", url: `/items/${itemId}/ratings`, authentication: true }),
    VALUATION: {
      GET: (itemId) => ({ method: "get", url: `/items/${itemId}/valuation`, authentication: true }),
      PUT: (itemId) => ({ method: "put", url: `/items/${itemId}/valuation`, authentication: true }),
    },
    GENRES: { method: "get", url: "/genres?limit=30", authentication: true },
    COMMENTS: {
      GET: (itemId) => ({ method: "get", url: `/items/${itemId}/comments`, authentication: true }),
      GET_ONE: (itemId, commentId) => ({ method: "get", url: `/items/${itemId}/comments/${commentId}` }),
      CREATE: (itemId) => ({ method: "post", url: `/items/${itemId}/comments`, authentication: true }),
      EDIT: (itemId, commentId) => ({ method: "put", url: `/items/${itemId}/comments/${commentId}`, authentication: true }),
      DELETE: (itemId, commentId) => ({ method: "delete", url: `/items/${itemId}/comments/${commentId}`, authentication: true }),
      REPORT: (itemId, commentId) => ({ method: "put", url: `/items/${itemId}/comments/${commentId}/report`, authentication: true }),
      REACTIONS: {
        CREATE: (itemId, commentId, reactionId) => ({ method: "put", url: `/items/${itemId}/comments/${commentId}/${reactionId}`, authentication: true }),
        DELETE: (itemId, commentId, reactionId) => ({ method: "delete", url: `/items/${itemId}/comments/${commentId}/${reactionId}`, authentication: true }),
      },
      GIVE_AWARD: (itemId, commentId, awardId) => ({ method: "post", url: `/items/${itemId}/comments/${commentId}/awards/${awardId}`, authentication: true }),
    },
    CELEBRITIES_EXTRAS: {
      MOVIES: (itemId) => ({ method: "get", url: `/items/${itemId}/celebrity/movies`, authentication: true }),
      GENRES: (itemId) => ({ method: "get", url: `/items/${itemId}/celebrity/genres`, authentication: true }),
      COSTARS: (itemId) => ({ method: "get", url: `/items/${itemId}/celebrity/costars`, authentication: true }),
    },
    GET_CREDITS: (itemId) => ({ method: "get", url: `/credits/${itemId}` }),
    GET_TITLES: (itemId) => ({ method: "get", url: `/items/${itemId}/celebrity/titles` }),
    RECOMMENDATIONS: {
      GET: (itemId) => ({ method: "get", url: `/items/${itemId}/recommendations`, authentication: true }),
      PUT: (itemId, itemRecommendedId) => ({ method: "put", url: `/items/${itemId}/recommendations/${itemRecommendedId}`, authentication: true }),
    },
  },
  SHARING: {
    GET: { method: "get", url: "/sharing-text" },
    REGISTER: (itemId) => ({ method: "post", url: `/items/${itemId}/sharing`, authentication: true }),
  },
  FEED: {
    GET: { method: "get", url: "/feedv2", authentication: true },
  },
  FEEDBACK: { method: "put", url: "/users/feedback", authentication: true },
  AWARDS: { method: "get", url: "/awards-list", authentication: true },
  RECOMMENDATIONS: {
    GET: (typeid, genres) => ({ method: "get", url: `/recommender?typeId=${typeid}&genres=${genres}`, authentication: true }),
    RANDOM: { method: "get", url: "/recommender", authentication: true },
  },
  GREETINGS: (itemId) => ({ method: "put", url: `/greetings/${itemId}`, authentication: true }),
  JUST_WATCH: (itemType, justWatchItemId, language, isoCountryCode) => ({ method: "get", url: `/content/titles/${itemType}/${justWatchItemId}/locale/${language}_${isoCountryCode}`, overrideBaseUrl: "https://apis.justwatch.com" }),

});
