/* eslint-disable no-console */
import { hit } from "./hit";
import { ITEMS } from "./APIEndpoints";

const get = async (id) => hit(ITEMS.GET(id));
const getGenres = async () => hit(ITEMS.GENRES);

const setValuation = async (id, valuation) => hit(ITEMS.VALUATION.PUT(id), { valuation });
const getValuation = async (id) => hit(ITEMS.VALUATION.GET(id));

const createComment = async (id, comment, parentId) => hit(ITEMS.COMMENTS.CREATE(id), { comment, parentCommentId: parentId || null });
const editComment = async (itemId, commentId, comment) => hit(ITEMS.COMMENTS.EDIT(itemId, commentId), { comment: comment });
const deleteComment = async (itemId, commentId) => hit(ITEMS.COMMENTS.DELETE(itemId, commentId));
const reportComment = async (itemId, commentId) => hit(ITEMS.COMMENTS.REPORT(itemId, commentId));
const getComment = async (itemId, commentId) => hit(ITEMS.COMMENTS.GET_ONE(itemId, commentId));
const setCommentReaction = async (itemId, commentId, reactionId) => hit(ITEMS.COMMENTS.REACTIONS.CREATE(itemId, commentId, reactionId));
const deleteCommentReaction = async (itemId, commentId, reactionId) => hit(ITEMS.COMMENTS.REACTIONS.DELETE(itemId, commentId, reactionId));

const celebritiesMovies = async (itemId) => hit(ITEMS.CELEBRITIES_EXTRAS.MOVIES(itemId));
const celebritiesGenres = async (itemId) => hit(ITEMS.CELEBRITIES_EXTRAS.GENRES(itemId));
const celebritiesCoStars = async (itemId) => hit(ITEMS.CELEBRITIES_EXTRAS.COSTARS(itemId));

const putRecommendation = async (itemId, recommendationId) => hit(ITEMS.RECOMMENDATIONS.PUT(itemId, recommendationId));

export const items = {
  get,
  getGenres,
  setValuation,
  getValuation,
  createComment,
  editComment,
  deleteComment,
  reportComment,
  getComment,
  setCommentReaction,
  deleteCommentReaction,
  celebritiesMovies,
  celebritiesGenres,
  celebritiesCoStars,
  putRecommendation,
};
