import { hit } from "./hit";
import { SHOWS, TRIVIAS } from "./APIEndpoints";

const getTrivia = (id) => hit(TRIVIAS.GET_QUESTIONS(id));
const postShowAnswer = ({
  showId,
  participantId,
  optionId,
  questionId,
}) => hit(SHOWS.SHOW_POST_ANSWERS(showId, participantId), { optionId, questionId });

const getShows = () => hit(TRIVIAS.SHOWS_ONLINE);
const getShow = (id) => hit(TRIVIAS.SHOW_ONLINE(id));

const joinShow = ({ showId, profileId, metadata }) => (
  hit(TRIVIAS.JOIN_SHOW(showId), { profileId, metadata })
);

const getWinners = (showId) => hit(SHOWS.GET_WINNERS_BY_ID(showId));
const postAnswer = async (triviaId, body) => hit(TRIVIAS.POST_ANSWERS(triviaId), body);

export const trivias = {
  getTrivia,
  postAnswer,
  postShowAnswer,
  getWinners,
  getShows,
  getShow,
  joinShow,
};
